
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    filters: {
        letter(value: number) {
            const letters = ['A', 'B', 'C', 'D'];
            return letters[value - 1];
        },
    }
})

class PQuestaoCard extends Vue {
    @Prop({ type: Number, required: true })
    private value!: number;

    @Prop({ type: Number, required: true })
    private idQuestao!: number;

    @Prop({ type: String, required: true })
    private title!: string;

    @Prop({ type: Number, required: true })
    private index!: number;

    @Prop({ type: Boolean, required: true })
    private alternativaCorreta!: boolean;

    @Prop({ type: Boolean, required: false })
    private alternativaSelecionada!: boolean;

    @Prop({ type: Boolean, default: false })
    private showResult!: boolean;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private get selectedValue() {
        return this.value;
    }
    
    private set selectedValue(value: number) {
        this.$emit('input', value);
    }

    private get classeCard(): string {
        if(this.showResult) {
            return this.alternativaCorreta ? '--alternativa-correta' : '--alternativa-errada';
        }
        else {
            return '';
        }
    }
}

export default PQuestaoCard;
